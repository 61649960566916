@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

.footer {
  background-color: $black;
  color: $white;
  a {
    color: $white;
  }
  p {
    color: $white;
    margin-bottom: rem(12);
  }
  ul {
    list-style: none;
  }
  li {
    margin-bottom: rem(24);
    &:last-child {
      margin-bottom: 0;
    }
  }
  .footer-heading {
    font-size: rem(12);
    line-height: rem(28);
    color: rgba($white, 0.5);
  }
  .footer-top {
    @include padding-top-half;
    @include padding-bottom-half;
  }
  .footer-bottom {
    // border-top: rem(2) solid rgba($white, 0.25);
    padding: rem(20) 0;
    .row {
      margin-bottom: 0;
    }
    [class*="col-"] {
      padding-bottom: 0;
    }
    ul {
      display: inline-block;
    }
    li {
      display: inline-block;
      margin-bottom: 0;
      margin-right: rem(24);
      &:last-child {
        margin-right: 0;
      }
    }
    a {
      font-size: rem(12);
      &::after {
        margin-top: 0;
      }
    }
    .copyright {
      margin-right: rem(24);
      display: inline-block;
      opacity: 0.7;
    }
  }
}

@media screen and (min-width: $screen-tablet) {
  .footer {
    p {
      margin-bottom: rem(28);
    }

    .footer-heading {
      font-size: rem(14);
      line-height: rem(32);
    }

    .footer-bottom {
      padding: rem(33) 0 rem(30) 0;
      a {
        font-size: rem(14);
      }
    }
  }
}
