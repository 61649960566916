@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

.about-us-section {
    p {
        opacity: 0.8;
        &:not(:last-child) {
            margin-bottom: rem(28);
        }
    }

    @include fade-in-up(0.5s);
}

@media screen and (min-width: $screen-tablet) {
    .about-us-section {
        transition-delay: 0s;
    }
}

@media screen and (min-width: $screen-desktop) {
    .about-us-section {
        p {
            &:not(:last-child) {
                margin-bottom: rem(32);
            }
        }
    }
}
