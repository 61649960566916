@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

.link-underlined {
    display: inline-block;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;

    &:after {
        content: "";
        display: block;
        height: rem(2);
        margin-top: rem(4);
        width: 100%;
        background-color: currentColor;
        opacity: 0.25;
        transition: opacity 0.4s ease;
    }

    &:hover {
        &:after {
            opacity: 1;
        }
    }
}

@media screen and (min-width: $screen-desktop) {
    .link-underlined {
        display: inline-block;

        &:after {
            margin-top: rem(8);
        }
    }
}
