@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

@keyframes button-pulse {
    20%,
    100% {
        transform: scale(1);
    }

    0% {
        transform: scale(1);
    }

    10% {
        transform: scale(1.2);
    }
}

$limit: 6;
$btn-size: 42;
$btn-size-lg: 60;
$dot-size: 2;
$dot-size-lg: 4;
$dot-middle: ($btn-size - $dot-size)/2;
$dot-end: $btn-size - $dot-size;
$dot-middle-lg: ($btn-size-lg - $dot-size-lg)/2;
$dot-end-lg: $btn-size-lg - $dot-size-lg;
$dot-left: $btn-size/4 - $dot-size/2;
$dot-right: $btn-size/4 * 3 - $dot-size/2;
$dot-left-lg: $btn-size-lg/4 - $dot-size-lg/2;
$dot-right-lg: $btn-size-lg/4 * 3 - $dot-size-lg/2;

.menu-btn {
    z-index: 1;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
    width: rem($btn-size);
    height: rem($btn-size);
    padding: 0;
    margin: 0;
    animation: button-pulse 4s infinite ease-in-out;

    &.transitioning,
    &.open {
        animation: none;
    }

    &.open {
        span {
            background-color: $white;

            &:nth-child(1) {
                top: 0;
                left: 0;
            }

            &:nth-child(2) {
                top: rem($dot-left);
                left: rem($dot-right);
            }

            &:nth-child(3) {
                top: 0;
                left: rem($dot-end);
            }

            &:nth-child(4) {
                top: rem($dot-left);
                left: rem($dot-left);
            }

            &:nth-child(5) {
                top: rem($dot-middle);
                left: rem($dot-middle);
            }

            &:nth-child(6) {
                top: rem($dot-right);
                left: rem($dot-left);
            }

            &:nth-child(7) {
                top: rem($dot-end);
                left: 0;
            }

            &:nth-child(8) {
                top: rem($dot-right);
                left: rem($dot-right);
            }

            &:nth-child(9) {
                top: rem($dot-end);
                left: rem($dot-end);
            }
        }
    }

    &.transitioning {
        span {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8),
            &:nth-child(9) {
                top: rem($dot-middle);
                left: rem($dot-middle);
            }
        }
    }

    span {
        transition: all 0.4s ease;
        width: rem(2);
        height: rem(2);
        display: block;
        background-color: $black;
        position: absolute;

        left: 0;
        top: rem($dot-end);

        &:nth-child(3n + 2) {
            left: rem($dot-middle);
        }

        &:nth-child(3n + 3) {
            left: rem($dot-end);
        }

        &:nth-child(-n + 6) {
            top: rem($dot-middle);
        }

        &:nth-child(-n + 3) {
            top: 0;
        }
    }
}

.menu {
    transition: opacity 0.5s ease, visibility 0.5s ease;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow-y: auto;
    background: $black;
    opacity: 0;
    visibility: hidden;
    z-index: 10;

    .logo {
        position: fixed;
        top: rem(22);
        left: rem(22);
    }

    .menu-btn {
        position: fixed;
        top: rem(22);
        right: rem(22);
    }

    .grid {
        min-height: 100%;
        grid-template-rows: 1fr auto;
        row-gap: 0;
    }

    a {
        color: $white;
    }

    &.open {
        opacity: 1;
        visibility: visible;

        .nav-links {
            li {
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8) {
                    opacity: 1;
                }
            }
        }

        .social-links,
        .footer-links {
            opacity: 1;
        }
    }
}

.nav-links {
    grid-column: 1 / span 2;
    display: flex;
    align-items: center;

    ul {
        padding: rem(90) 0 rem(45) 0;
    }

    li {
        padding-bottom: rem(16);
        transition: opacity 0.9s ease;
        opacity: 0;
        @for $i from 1 through $limit {
            &:nth-child(#{$i}) {
                transition-delay: ($i * 50ms) + 250ms;
            }
        }
    }

    a {
        display: block;
        opacity: 0.5;
        transition: opacity 0.9s ease;
        &.active,
        &:hover {
            opacity: 1;
        }
    }
}
.social-links {
    order: 1;
    margin-top: rem(12);
}

.social-links,
.footer-links {
    grid-column: 1 / span 2;
    transition: opacity 0.9s 1s ease;
    opacity: 0;

    a {
        margin-right: rem(16);
    }
}

@media screen and (min-width: $screen-tablet) {
    .nav-links {
        grid-column: 7 / span 6;

        ul {
            padding: rem(120) 0;
        }

        li {
            padding-bottom: rem(28);
        }
    }

    .social-links {
        order: 0;
        margin-top: 0;
        grid-column: 1 / span 6;
        a {
            margin-right: rem(12);
        }
    }

    .footer-links {
        grid-column: 7 / span 6;
        a {
            margin-right: rem(12);
        }
    }

    .menu {
        .logo {
            top: rem(40);
            left: rem(40);
        }

        .menu-btn {
            top: rem(40);
            right: rem(40);
        }
    }
}

@media screen and (min-width: $screen-desktop) {
    .menu-btn {
        width: rem($btn-size-lg);
        height: rem($btn-size-lg);

        &.open {
            span {
                &:nth-child(1) {
                    top: 0;
                    left: 0;
                }

                &:nth-child(2) {
                    top: rem($dot-left-lg);
                    left: rem($dot-right-lg);
                }

                &:nth-child(3) {
                    top: 0;
                    left: rem($dot-end-lg);
                }

                &:nth-child(4) {
                    top: rem($dot-left-lg);
                    left: rem($dot-left-lg);
                }

                &:nth-child(5) {
                    top: rem($dot-middle-lg);
                    left: rem($dot-middle-lg);
                }

                &:nth-child(6) {
                    top: rem($dot-right-lg);
                    left: rem($dot-left-lg);
                }

                &:nth-child(7) {
                    top: rem($dot-end-lg);
                    left: 0;
                }

                &:nth-child(8) {
                    top: rem($dot-right-lg);
                    left: rem($dot-right-lg);
                }

                &:nth-child(9) {
                    top: rem($dot-end-lg);
                    left: rem($dot-end-lg);
                }
            }
        }

        &.transitioning {
            span {
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8),
                &:nth-child(9) {
                    top: rem($dot-middle-lg);
                    left: rem($dot-middle-lg);
                }
            }
        }

        span {
            width: rem($dot-size-lg);
            height: rem($dot-size-lg);

            left: 0;
            top: rem($dot-end-lg);

            &:nth-child(3n + 2) {
                left: rem($dot-middle-lg);
            }

            &:nth-child(3n + 3) {
                left: rem($dot-end-lg);
            }

            &:nth-child(-n + 6) {
                top: rem($dot-middle-lg);
            }

            &:nth-child(-n + 3) {
                top: 0;
            }
        }
    }

    .nav-links {
        h2 {
            line-height: rem(52);
        }
    }
    .social-links,
    .footer-links {
        a {
            margin-right: rem(24);
        }
    }

    .menu {
        .logo {
            top: rem(60);
            left: rem(60);
        }

        .menu-btn {
            top: rem(60);
            right: rem(60);
        }
    }
}
