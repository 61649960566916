@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

.contact-cta {
    background-color: rgba($black, 0.9);
    color: $white;

    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;

    h2 {
        margin-bottom: rem(24);
    }

    a {
        color: $white;
    }
}

@media screen and (min-width: $screen-tablet) {
    .contact-cta {
        h2 {
            margin: 0;
        }

        .contact-cta-inner {
            display: flex;
            justify-content: space-between;
        }
    }
}
