@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

.banner-video {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    position: relative;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: rem(91);
    padding-bottom: rem(91);
    overflow: hidden;
    background-color: rgba($black, 0.05);
    transition: opacity 1.5s ease;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: rem(86);
        background-image: linear-gradient(
            to bottom,
            hsla(0, 0%, 0%, 0.46) 0%,
            hsla(0, 0%, 0%, 0.454) 8.1%,
            hsla(0, 0%, 0%, 0.438) 15.5%,
            hsla(0, 0%, 0%, 0.412) 22.5%,
            hsla(0, 0%, 0%, 0.379) 29%,
            hsla(0, 0%, 0%, 0.341) 35.3%,
            hsla(0, 0%, 0%, 0.298) 41.2%,
            hsla(0, 0%, 0%, 0.253) 47.1%,
            hsla(0, 0%, 0%, 0.207) 52.9%,
            hsla(0, 0%, 0%, 0.162) 58.8%,
            hsla(0, 0%, 0%, 0.119) 64.7%,
            hsla(0, 0%, 0%, 0.081) 71%,
            hsla(0, 0%, 0%, 0.048) 77.5%,
            hsla(0, 0%, 0%, 0.022) 84.5%,
            hsla(0, 0%, 0%, 0.006) 91.9%,
            hsla(0, 0%, 0%, 0) 100%
        );
        background-repeat: no-repeat;
        opacity: 1;
        transition: opacity 0.4s ease;
    }

    .banner-video-img,
    .banner-video-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        transition: transform 2s ease;
    }

    .banner-video-video {
        object-fit: cover;
    }

    h1 {
        transition: transform 1s 0.5s, opacity 1s 0.5s ease;
    }

    .banner-video-btn {
        margin-top: rem(map-get($gutter, "mobile"));
        display: inline-flex;
        align-items: center;
        transition: opacity 1s 1s ease;
        background: none;
        border: none;
        padding: rem(17) rem(25);
        color: $white;
        cursor: pointer;
        position: relative;

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: $black;
            opacity: 0.25;
            z-index: -1;
            transition: opacity 0.3s ease;
        }

        &:hover {
            &:before {
                opacity: 0.5;
            }
        }
    }

    .banner-video-btn-label {
        font-size: rem(16);
        line-height: rem(20);
        letter-spacing: rem(0.25);
        font-weight: map-get($bodytype, bold);

        &:not(:first-child) {
            margin-left: rem(18);
        }
    }

    .scroll-down {
        position: absolute;
        bottom: rem(map-get($gutter, "mobile"));
        right: rem(map-get($gutter, "mobile"));
    }

    &.loading {
        opacity: 0;
        .banner-video-img,
        .banner-video-video {
            transform: scale(1.2);
        }
        h1 {
            opacity: 0;
            transform: translateY(50px);
        }
        .banner-video-btn {
            opacity: 0;
        }
    }
}
@media screen and (min-width: $screen-tablet) {
    .banner-video {
        //justify-content: flex-end;

        padding-top: rem(130);
        padding-bottom: rem(130);

        &:after {
            height: rem(140);
        }

        .banner-video-btn {
            margin-top: rem(map-get($gutter, "tablet"));
        }

        .scroll-down {
            bottom: rem(map-get($gutter, "tablet"));
            right: rem(map-get($gutter, "tablet"));
        }
    }
}

@media screen and (min-width: $screen-desktop) {
    .banner-video {
        padding-top: rem(180);
        padding-bottom: rem(180);

        &:after {
            height: rem(180);
        }

        .banner-video-btn {
            margin-top: rem(map-get($gutter, "desktop"));
        }

        .scroll-down {
            bottom: rem(map-get($gutter, "desktop"));
            right: rem(map-get($gutter, "desktop"));
        }
    }
}
