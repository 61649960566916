@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

.bg-img {
    width: 100%;
    height: 100%;
    background-color: $black;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    &.bg-img-overlay {
        position: relative;

        &:before {
            content: "";
            @include black-overlay;
        }
    }
}
