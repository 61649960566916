@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

.play-btn {
    height: rem(40);
    width: rem(40);
    position: absolute;
    left: 50%;
    top: 50%;
    // transform: translate(-50%, -50%);
    // transition: opacity 0.5s ease;

    svg {
        height: rem(40);
        width: rem(40);
    }

    &.hidden {
        opacity: 0;
        visibility: hidden;
    }
}

@media screen and (min-width: $screen-tablet) {
    .play-btn {
        width: rem(67);
        height: rem(67);

        svg {
            width: rem(67);
            height: rem(67);
        }
    }
}

@media screen and (min-width: $screen-desktop) {
    .play-btn {
        width: rem(83);
        height: rem(83);

        svg {
            width: rem(83);
            height: rem(83);
        }
    }
}

// @media (pointer: fine) {
//     /* Rules for devices with mouse here */

//     .play-btn {
//         height: rem(84);
//         width: rem(84);
//         margin: rem(-42) 0 0 rem(-42);
//         position: fixed;
//         top: 0;
//         right: auto;
//         bottom: auto;
//         left: 0;
//         transform: none;
//         z-index: 1600000;
//         pointer-events: none;
//         will-change: transform;
//         opacity: 0;
//         visibility: hidden;

//         &.active {
//             opacity: 1;
//             visibility: visible;
//         }
//     }
// }
