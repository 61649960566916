@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

.full-screen-video {
    transition: opacity 0.5s ease, visibility 0.6s ease;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: $black;
    opacity: 0;
    visibility: hidden;
    z-index: 100;

    &.playing {
        opacity: 1;
        visibility: visible;
    }

    video {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .close-btn,
    .pause-btn {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
        width: rem(64);
        height: rem(64);
        border: none;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($black, 0.25);
        transition: background-color 0.3s ease;

        &:hover {
            background-color: rgba($black, 0.5);
        }
    }

    .close-btn {
        top: rem(22);
    }

    .pause-btn {
        bottom: rem(22);
    }
}

@media screen and (min-width: $screen-tablet) {
    .full-screen-video {
        .close-btn {
            top: rem(40);
        }

        .pause-btn {
            bottom: rem(40);
        }
    }
}

@media screen and (min-width: $screen-desktop) {
    .full-screen-video {
        .close-btn {
            top: rem(60);
        }

        .pause-btn {
            bottom: rem(60);
        }
    }
}
