@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

.page-link-card {
    display: flex;
    flex-direction: column;
    height: 100%;

    .page-link-card-category {
        padding-bottom: rem(10);
        border-bottom: rem(1) solid $black;
        margin-bottom: rem(24);
    }

    .page-link-img-container {
        @include aspect-ratio-box(3, 4);
        margin-bottom: rem(20);
        position: relative;
        overflow: hidden;
    }

    .page-link-img {
        position: absolute;
        top: 0;
        left: 0;
        will-change: transform;
        transform: scale(1);
        transition: transform 1s;
    }

    .page-link-card-title {
        //margin-bottom: rem(40);
    }

    .page-link-card-sub-title {
        margin-bottom: rem(10);
    }

    &:hover {
        .page-link-img {
            transform: scale(1.04);
        }
    }
}

@media screen and (min-width: $screen-tablet) {
    .page-link-grid {
        .page-link-img-container {
            margin-bottom: rem(32);

            height: 18vw;
        }
    }
}
