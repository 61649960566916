@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

.page-heading {
    padding-top: rem(180);
    position: relative;

    .pre-heading {
        margin-bottom: rem(20);
    }

    .heading {
        transition: transform 1s ease, opacity 1s ease;
    }

    .scroll-down {
        display: none;
    }

    &.loading {
        .heading {
            opacity: 0;
            transform: translateY(50px);
        }
        .scroll-down {
            opacity: 0;
        }
    }
}

@media screen and (min-width: $screen-tablet) {
    .page-heading {
        padding-top: rem(160);

        .scroll-down {
            display: flex;
            position: absolute;
            bottom: rem(map-get($gutter, "tablet"));
            right: rem(map-get($gutter, "tablet"));
            transition: opacity 1s 1s ease;
        }
    }
}

@media screen and (min-width: $screen-desktop) {
    .page-heading {
        padding-top: rem(240);

        .pre-heading {
            margin-bottom: rem(40);
        }

        .scroll-down {
            bottom: rem(map-get($gutter, "desktop"));
            right: rem(map-get($gutter, "desktop"));
        }
    }
}
