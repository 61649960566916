@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

.contact-details {
    transition: opacity 1s 0.5s ease;

    .address {
        margin-bottom: rem(24);
    }

    .contact-label {
        width: 30%;
        flex-shrink: 0;
    }

    .contact-field {
        display: flex;

        &:not(:last-child) {
            margin-bottom: rem(22);
        }

        a {
            &:not(:last-child) {
                margin-right: rem(10);
            }
        }
    }

    .contact-value {
        margin-bottom: rem(-12);
        > * {
            padding-bottom: rem(12);
        }
    }

    &.loading {
        opacity: 0;
    }
}

@media screen and (min-width: $screen-sm) {
    .contact-details {
        .contact-field {
            a {
                &:not(:last-child) {
                    margin-right: rem(32);
                }
            }
        }
    }
}

@media screen and (min-width: $screen-tablet) {
    .contact-details {
        .contact-details-address {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            a {
                margin-top: auto;
            }
        }

        .contact-details-links {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
}

@media screen and (min-width: $screen-desktop) {
    .contact-details {
        .contact-details-links {
            justify-content: flex-start;
        }
        .contact-field {
            &:last-child {
                margin-top: auto;
            }
        }
    }
}
