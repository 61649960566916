@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

@keyframes scrollanim {
    0% {
        transform: translateY(0);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateY(28px);
    }
}

.scroll-down {
    @include clear-default-appearance;
    cursor: pointer;
    background-color: transparent;
    width: rem(60);
    height: rem(48);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    svg {
        display: block;
        animation: scrollanim 1.7s ease infinite;
    }

    &:after {
        content: "";
        display: block;
        height: rem(2);
        margin-top: rem(4);
        width: 100%;
        background-color: currentColor;
        opacity: 0.25;
        transition: opacity 0.4s ease;
    }

    &:hover {
        &:after {
            opacity: 1;
        }
    }
}

.scroll-down-white {
    color: $white;
}

.scroll-down-black {
    color: $black;
}
