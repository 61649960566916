@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

.nav-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: rem(22);
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.5s ease, padding 0.5s ease;

    > * {
        transition: transform 0.8s ease;
    }

    &.sticky {
        position: fixed;
        transform: translateY(-100%);
        transition: transform 1s ease;

        &.visible {
            transform: translateY(0);
            background-color: $black;
            padding-top: rem(22);
            padding-bottom: rem(22);

            > * {
                transition: none;
                transform: scale(0.7);
            }

            .logo {
                color: $white !important;
            }

            .menu-btn {
                span {
                    background-color: $white;
                }
            }
        }
    }

    &.nav-bar-white {
        .logo {
            color: $black;
        }

        .menu-btn {
            span {
                background-color: $black;
            }
        }
    }

    &.nav-bar-black {
        .logo {
            color: $white !important;
        }

        .menu-btn {
            span {
                background-color: $white;
            }
        }
    }
}

@media screen and (min-width: $screen-tablet) {
    .nav-bar {
        padding: rem(40);
    }
}

@media screen and (min-width: $screen-desktop) {
    .nav-bar {
        padding: rem(60);
    }
}
