@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

.video {
    @include aspect-ratio-box(9, 16);
    position: relative;
    @include fade-in-up;

    &.active {
        .video-thumbnail {
            opacity: 0;
            visibility: hidden;
        }

        video {
            opacity: 1;
            visibility: visible;
        }
    }

    .video-thumbnail,
    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity 0.5s ease;
    }

    .video-thumbnail {
        border: none;
        background: none;
        padding: 0;
    }

    video {
        opacity: 0;
        visibility: hidden;
        object-fit: cover;
    }
}

@media (pointer: fine) {
    /* Rules for devices with mouse here */

    .video {
        > * {
            cursor: none;
        }

        &.active {
            > * {
                cursor: default;
            }
        }
    }
}
