@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

$limit: 3;

.share {
    ul {
        list-style: none;
        position: absolute;
        top: 100%;
        left: rem(-40);
        background-color: $white;
        visibility: hidden;
        opacity: 0;
        z-index: 1;
        padding: rem(40);
        margin: 0;
        transition: opacity 0.5s ease, transition 0.6s ease;
    }

    li {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s ease, transition 0.6s ease;
        margin-bottom: rem(20);

        &:last-child {
            margin: 0;
        }

        @for $i from 1 through $limit {
            &:nth-child(#{$i}) {
                transition-delay: ($i * 100ms) + 50ms;
            }
        }
    }

    svg {
        transition: transform 0.5s ease;
        margin-left: rem(7);
    }

    &.open {
        ul,
        li {
            opacity: 1;
            visibility: visible;
        }

        svg {
            transform: rotate(270deg);
        }
    }
}
