@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

.prosumer-report {
    position: relative;

    .disabled{
        width: 100%;
        height: 100%;
        z-index: 100;
        background: rgba(0,0,0,0);
        position: absolute;
        opacity: 0.5;
        pointer-events: none;
        position: relative;
    }

    .error-message{
        color:#ff3f3f;
        font-size: rem(10);
        font-weight: 500;
        line-height: rem(20);
        opacity: 0.99;
    }

    .success-message{
       margin-bottom: rem(20);
       padding-top: rem(40);
    }

    form {
        opacity: 1;
        visibility: visible;
        position: relative;

        
        input[type="text"],
        input[type="email"] {
            @include clear-default-appearance;
            font-weight: 400;
            border-bottom: rem(1) solid white;
            font-size: rem(12);
            line-height: rem(19);
            width: 100%;
            margin-bottom: rem(20);
            opacity: 0.75;
            color:white;

            &:focus {
                outline: 0;
                opacity: 0.75;
            }

            &::-webkit-input-placeholder {
                font-size: rem(12);
                line-height: rem(19);
                color:$light_grey;
            }

            &::-ms-input-placeholder {
                font-size: rem(12);
                line-height: rem(19);
                color:$light_grey;
            }

            &::placeholder {
                font-size: rem(12);
                line-height: rem(19);
                color:$light_grey;
            }
            &:hover {
                opacity: 1;
            }
        }

        input[type="email"] {
            font-weight: 700;

            &:focus {
                outline: 0;
                opacity: 0.75;
            }
        }

        .submit-btn {
            display: inline-block;
            color:white;
            position: relative;
            cursor: pointer;
            background: none;
            border: none;
            padding: 0;
            font-size: rem(12);
            line-height: rem(19);

            &:after {
                content: "";
                display: block;
                height: rem(2);
                margin-top: rem(4);
                width: 100%;
                background-color: currentColor;
                opacity: 0.25;
                transition: opacity 0.4s ease;
                margin-bottom: rem(75);
            }

            &:hover {
                &:after {
                    opacity: 1;
                }
            }
        }

        .grecaptcha-badge {
            bottom: unset !important;
            right: 0 !important;
            margin-top: rem(40);
        }
    }
}

@media screen and (min-width: $screen-tablet) {
    .prosumer-report {
        .success-message{
            margin-bottom: rem(40);
            padding-top: 0;
         }

        form {
            input[type="text"],
            input[type="email"] {
                @include clear-default-appearance;
                font-weight: 400;
                color:white;
                font-size: rem(14);
                width: 100%;
                margin-bottom: rem(40);
                border-bottom: rem(1) solid $grey;
            }

            input[type="email"] {
                font-weight: 700;
                font-size: rem(16);
                line-height: rem(22);
            }
            .form-submit {
                .submit-btn {
                    display: inline-block;
                    font-weight: 700;
                    font-size: rem(14);
                    color:white;

                    &:after {
                        margin-top: rem(8);
                        margin-bottom: rem(60);
                    }
                }
            }
            .grecaptcha-badge {
                margin-top: rem(35);
            }
        }
    }
}


@media only screen and (max-width: 1023px) {
    .prosumer-report form {
        padding-top: 2.5rem;
    }
    .page-heading {
        padding-bottom: 2.5rem;
      }
}

@media only screen and (min-width: 400px) {
    .prosumer-report form {
        padding-top: 2.5rem;
    }
    .page-heading {
        padding-bottom: 2.5rem;
      }
}



