@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

$limit: 9;

.page-link-grid {
    .row {
        margin-bottom: 0;
    }

    .page-link-grid-item {
        padding-bottom: rem(map-get($row-gap, "mobile"));

        @include fade-in-up;
        @for $i from 1 through $limit {
            &:nth-child(#{$i}) {
                transition-delay: ($i + 0) * 110ms;
            }
        }
    }
}

@media screen and (min-width: $screen-tablet) {
    .page-link-grid {
        .page-link-grid-item {
        }
    }
}

@media screen and (min-width: $screen-desktop) {
    .page-link-grid {
        .page-link-grid-item {
        }
    }
}
