@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

$limit: 10;

.icon-grid {
    .icon-grid-item {
        flex-basis: 50%;
        text-align: center;

        @include fade-in-up;
        @for $i from 1 through $limit {
            &:nth-child(#{$i}) {
                transition-delay: ($i + 1) * 110ms;
            }
        }
        p {
            margin-top: rem(40);
        }
        svg {
            height: rem(70);
        }
    }
}

@media screen and (min-width: $screen-tablet) {
    .icon-grid {
        .icon-grid-item {
            flex-basis: 20%;
            flex-grow: 0;
            text-align: left;
        }
    }
}

