@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

.full-width-gif {
    @include fade-in-up(1.5s);

    img {
        width: 100%;
        height: auto;
    }
}
