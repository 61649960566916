@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

.map-container {
    height: 0;
    width: 100%;
    padding-top: 100%;
    position: relative;
    @include fade-in-up(300ms);
}

.map {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: block;
    background-color: rgba($black, 0.25);

    .map-marker svg {
        transform: translate(-50%, -100%);
    }
}

@media screen and (min-width: $screen-tablet) {
    .map-container {
        padding-top: 9/16 * 100%;
    }
}

@media screen and (min-width: $screen-desktop) {
    .map-container {
        padding-top: 7/15 * 100%;
    }
}
