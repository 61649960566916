@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

$limit: 3;

.featured-articles {
    background-color: $light-grey;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        border-bottom: rem(2) solid $grey;
        display: flex;
        margin: 0;
        padding: 0;
        padding-bottom: rem(40);
        margin-bottom: rem(40);
        transition: opacity 0.9s ease;
        @for $i from 1 through $limit {
            &:nth-child(#{$i}) {
                transition-delay: ($i * 100ms) + 250ms;
            }
        }

        &:last-child {
            border: none;
            padding: 0;
            margin: 0;
        }
    }

    &.not-visible {
        li {
            opacity: 0;
        }
    }
}
