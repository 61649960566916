@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

.work-list {
    cursor: url("../../assets/images/cursor/scroll.png") 30 50, auto;

    .work-link,
    .work-link > * {
        cursor: url("../../assets/images/cursor/click.png") 16 16, pointer;
    }

    position: relative;
    padding-top: rem(100);

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: $black;
        opacity: 0;
        transition: opacity 0.7s ease;
        z-index: -1;
    }

    &.hovered {
        &:before {
            opacity: 1;
        }
    }

    .work-link {
        display: block;

        @include fade-in-up(100ms);

        &:nth-child(2) {
            transition-delay: 200ms;
        }

        h2 {
            margin-bottom: rem(10);

            span:not(:last-child) {
                &:after {
                    content: " ";
                }
            }
        }

        p {
            margin-bottom: rem(8);
        }
    }

    .work-link-img {
        height: 0;
        padding-top: 9 / 16 * 100%;
        margin-bottom: rem(20);
    }
}

@media screen and (min-width: $screen-tablet) {
    .work-list {
        .work-link {
            color: $white;
            text-align: center;
            position: relative;

            &.fade {
                opacity: 0.3;
            }

            h2 {
                margin-bottom: rem(8);
                span {
                    display: block;
                }
            }

            p {
                margin-bottom: 0;
            }

            .work-link-heading,
            .link-underlined {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: transform 0.5s ease, opacity 0.5s ease, visibility 0.6s ease;
            }

            .work-link-heading {
                transition-delay: 0.2s;
            }

            .link-underlined {
                visibility: hidden;
                opacity: 0;
                transform: translate(-50%, calc(-50% + 50px));
            }

            &:hover {
                .work-link-heading {
                    visibility: hidden;
                    opacity: 0;
                    transform: translate(-50%, calc(-50% - 50px));
                    transition-delay: 0s;
                }
                .link-underlined {
                    visibility: visible;
                    opacity: 1;
                    transform: translate(-50%, -50%);
                    transition-delay: 0.2s;
                }
            }
        }

        .work-link-img {
            margin: 0;
        }
    }
}

@media screen and (min-width: $screen-desktop) {
    .work-list {
        .work-link {
            h2 {
            }
        }
    }
}
