@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

$limit: 3;

.audio-list {
    @include fade-in-up(1.5s);

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        border-bottom: rem(2) solid $grey;

        margin: 0;
        padding: rem(40) 0;
        transition: opacity 0.9s ease;
        @for $i from 1 through $limit {
            &:nth-child(#{$i}) {
                transition-delay: ($i * 100ms) + 250ms;
            }
        }

        &:last-child {
            border: none;
        }
    }

    p {
        margin-bottom: rem(20);
    }

    audio {
        width: 100%;
    }
}

@media screen and (min-width: $screen-tablet) {
    .audio-list {
        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        p {
            flex-shrink: 0;
            margin-right: rem(40);
            margin-bottom: 0;
        }

        audio {
            width: 80%;
        }
    }
}
