@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

.next-page {
    background: $white;
    color: $black;
    padding-top: rem(60);

    .next-page-inner {
        margin-bottom: rem(40);
    }

    .next-page-pre-heading {
        margin-bottom: rem(20);
    }

    .next-page-image-container {
        @include aspect-ratio-box(284, 320);
        position: relative;
        transition: opacity 1.5s 1s ease;

        .bg-img {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    h2 {
        margin-bottom: rem(24);

        span:not(:last-child) {
            &:after {
                content: " ";
            }
        }
    }

    .next-page-heading {
        transition: transform 1s 0.5s, opacity 1s 0.5s ease;
    }

    a {
        transition: opacity 1s 0.75s ease;
    }

    &.not-visible {
        .next-page-heading {
            opacity: 0;
            transform: translateY(50px);
        }
        .next-page-image-container {
            opacity: 0;
        }
        a {
            opacity: 0;
        }
    }
}

@media screen and (min-width: $screen-tablet) {
    .next-page {
        padding-top: rem(140);

        .next-page-inner {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            a {
                flex-shrink: 0;
                margin-left: rem(40);
            }
        }

        .next-page-image-container {
            @include aspect-ratio-box(387, 1025);
        }

        h2 {
            font-size: rem(48);
            line-height: rem(48);
            letter-spacing: rem(-0.75);
            margin-bottom: 0;

            span {
                display: block;
            }
        }
    }
}

@media screen and (min-width: $screen-desktop) {
    .next-page {
        .next-page-pre-heading {
            margin-bottom: rem(30);
        }

        .next-page-image-container {
            @include aspect-ratio-box(540, 1920);
        }

        h2 {
            font-size: rem(72);
            line-height: rem(72);
        }

        .next-page-inner {
            // margin-bottom: rem(40);
            margin-bottom: 7.5rem;
        }
    }
}
