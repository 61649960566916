@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.quote {
    blockquote {
        margin: 0;
    }

    p {
        font-size: rem(24);
        line-height: rem(32);
        letter-spacing: rem(-0.25);
        margin-top: rem(18);
        margin-bottom: rem(26);
        font-weight: map-get($bodytype, bold);
    }

    figcaption {
        font-size: rem(14);
        line-height: rem(20);
        letter-spacing: rem(0.25);
    }
}
