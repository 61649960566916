@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

$limit: 6;

.article-link-list {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        border-bottom: rem(2) solid $grey;
        display: flex;
        margin: 0;
        padding: rem(40) 0;
        transition: opacity 0.9s ease;
        @for $i from 1 through $limit {
            &:nth-child(#{$i}) {
                transition-delay: ($i * 100ms) + 250ms;
            }
        }

        &:last-child {
            border: none;
        }
    }

    .article-date {
        display: none;
    }

    &.loading {
        li {
            opacity: 0;
        }
    }
}

@media screen and (min-width: $screen-tablet) {
    .article-link-list {

        .article-date {
            display: block;
            width: 1/8 * 100%;
        }
    }
}
