@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

.page-content {
    @include fade-in-up(1.3s);
    position: relative;

    p {
        opacity: 0.8;
    }

    h5 {
        margin-bottom: rem(40);
    }

    .page-content-details {
        order: -1;

        .details-label {
            margin-bottom: rem(20);
        }

        .details-value {
            opacity: 1;
        }

        a {
            display: none;
        }
    }
    .page-content-content {
        &:not(:last-child) {
            margin-bottom: rem(40);
        }
        p:not(:last-child) {
            margin-bottom: rem(40);
        }
    }
}

@media screen and (min-width: $screen-tablet) {
    .page-content {
        @include fade-in-up(0.5s);

        .page-content-details {
            order: unset;

            a {
                display: inline-block;
                margin-top: rem(60);
            }
        }
    }
}

@media screen and (min-width: $screen-desktop) {
    .page-content {
        .page-content-details {
            a {
                margin-top: rem(80);
            }
        }
    }
}
