@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

$limit: 6;

.case-study-grid {
    .filters {
        display: none;
    }

    .case-study-grid-item {
        // transition: opacity 1.5s cubic-bezier(0.35, 1, 0.45, 1) 100ms,
        //     transform 1.5s cubic-bezier(0.35, 1, 0.45, 1) 100ms;

        @include fade-in-up(110ms);

        @for $i from 1 through $limit {
            &:nth-child(#{$i}) {
                transition-delay: ($i + 1) * 110ms;
            }
        }
    }

    // &.loading {
    //     .case-study-grid-item {
    //         opacity: 0;
    //         transform: translateY(50px);
    //     }
    // }
}

@media screen and (min-width: $screen-tablet) {
    .case-study-grid {
        .filters {
            display: block;
            transition: opacity 700ms ease;

            ul {
                list-style: none;
            }

            button {
                background: none;
                border: none;
                padding: 0;
                font-weight: map-get($bodytype, bold);
                cursor: pointer;
            }

            li {
                opacity: 0.5;
                position: relative;
                transition: opacity 0.3s ease;

                &.selected {
                    opacity: 1;

                    &:before {
                        content: "";
                        width: rem(30);
                        height: rem(2);
                        background-color: $black;
                        position: absolute;
                        left: rem(-60);
                        top: rem(15);
                    }
                }
            }
        }

        // &.loading {
        //     .filters {
        //         opacity: 0;
        //     }
        // }

        // .case-study-grid-item {
        //     @for $i from 1 through $limit {
        //         &:nth-child(#{$i}) {
        //             transition-delay: $i * 100ms;
        //         }
        //     }
        // }

        .case-study-grid-item {
            &:nth-child(odd) {
                transition-delay: 110ms;
            }
            &:nth-child(even) {
                transition-delay: 220ms;
            }
        }
    }
}
