@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

.banner {
    @include margin-bottom;

    .grid {
        padding-top: rem(120);
        padding-bottom: rem(40);
        position: relative;
    }
    .heading {
        grid-column: 1 / span 2;
        transition: transform 1s ease, opacity 1s ease;
    }
    .pre-heading {
        margin-bottom: rem(10);
    }
    .image-container {
        @include aspect-ratio-box(9, 16);
        position: relative;
        transition: opacity 1.5s 0.5s ease;

        .bg-img {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .scroll-down,
    .share {
        display: none;
    }

    &.loading {
        .heading {
            opacity: 0;
            transform: translateY(50px);
        }
        .image-container {
            opacity: 0;
        }
        .scroll-down,
        .share {
            opacity: 0;
        }
    }
}

.banner-white {
    background: $white;
    color: $black;
}

.banner-black {
    background: $black;
    color: $white;
}

@media screen and (min-width: $screen-tablet) {
    .banner {
        .grid {
            padding-top: rem(160);
            padding-bottom: rem(40);
        }

        .heading {
            grid-column: 3 / span 8;
        }

        .pre-heading {
            margin-bottom: rem(22);
        }

        .scroll-down {
            display: flex;
            position: absolute;
            bottom: rem(map-get($gutter, "tablet"));
            right: rem(map-get($gutter, "tablet"));
            transition: opacity 1s 1s ease;
        }

        .share {
            display: flex;
            position: absolute;
            bottom: rem(map-get($gutter, "tablet"));
            left: rem(map-get($gutter, "tablet"));
            transition: opacity 1s 1s ease;
        }
    }
}

@media screen and (min-width: $screen-desktop) {
    .banner {
        .grid {
            padding-top: rem(240);
            padding-bottom: rem(50);
        }

        .pre-heading {
            margin-bottom: rem(46);
        }

        .scroll-down {
            bottom: rem(map-get($gutter, "desktop"));
            right: rem(map-get($gutter, "desktop"));
        }

        .share {
            bottom: rem(map-get($gutter, "desktop"));
            left: rem(map-get($gutter, "desktop"));
        }
    }
}
