@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

.case-study-intro {
    p {
        opacity: 0.8;
    }

    ul {
        padding-left: rem(20);
        opacity: 0.8;
    }
    
    h4:not(.project-heading) {
        opacity: 1;
    }

    a{
        display: inline-block;
        cursor: pointer;
        background: none;
        border: none;
        padding: 0;
    
        &:after {
            content: "";
            display: block;
            height: rem(0.5);
            width: 100%;
            background-color: currentColor;
            opacity: 0.25;
            transition: opacity 0.4s ease;
        }
    
        &:hover {
            &:after {
                opacity: 1;
            }
        }
    }

    
    @include fade-in-up(1.5s);
}

@media screen and (min-width: $screen-tablet) {
    .case-study-intro {
        @include fade-in-up;
    }
}

@media screen and (min-width: $screen-desktop) {
    .case-study-intro {
    }
}

/* Small devices (portrait tablets and large phones, 350px and up) */
@media only screen and (min-width: 350px) {
    .spacing-bottom {
        padding-bottom: 2.5rem;
    }
}

