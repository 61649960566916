@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

.logo {
    z-index: 1;

    svg {
        display: block;
        height: rem(47);
        width: rem(28);
    }
}

@media screen and (min-width: $screen-desktop) {
    .logo {
        svg {
            height: rem(61);
            width: rem(36);
        }
    }
}