.group-images-item {
  width: 100%;
}

/* Small devices (portrait tablets and large phones, up to 650px width) */
@media only screen and (max-width: 650px) {
  // Custom order for mobile images
  .reorder .row {
    display: flex;
    display: -ms-flex;
    flex-direction: column;
  }
  .re-order .row .first {
    order: 1;
  }

  .re-order .row .second {
    order: 2;
  }

  .re-order .row .third {
    order: 3;
  }

  .re-order .row .last {
    order: 4;
  }
}