@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

$limit: 10;

.image-grid {
    .image-grid-item {
        @include fade-in-up;
        @for $i from 1 through $limit {
            &:nth-child(#{$i}) {
                transition-delay: ($i + 1) * 110ms;
            }
        }
    }

    .image-grid-image-container {
        @include aspect-ratio-box(1, 1);
        box-shadow: 0 rem(4) rem(21) 0 rgba(0, 0, 0, 0.12);
        position: relative;
    }

    .image-grid-image {
        width: 80%;
        height: 80%;
        position: absolute;
        top: 50%;
        left: 50%;
        object-fit: contain;
        transform: translate(-50%, -50%);
    }
}
