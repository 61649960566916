@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

$limit: 3;

.staggered-images {
    .staggered-images-items {
        // display: flex;
        // align-items: flex-start;
    }

    .staggered-images-item {
        max-width: 100%;
        box-shadow: 0 rem(4) rem(21) 0 rgba(0, 0, 0, 0.12);
        margin-bottom: rem(40);

        &:last-child {
            margin-bottom: 0;
        }

        @include fade-in-up(110ms);

        @for $i from 1 through $limit {
            &:nth-child(#{$i}) {
                transition-delay: ($i + 1) * 110ms;
            }
        }
    }

    &.not-visible {
        .staggered-images-item {
            opacity: 0;
            transform: translateY(50px);
        }
    }
}

@media screen and (min-width: $screen-sm) {
    .staggered-images {
        .staggered-images-items {
            display: flex;
            align-items: flex-start;
        }

        .staggered-images-item {
            max-width: calc((100% - 80px)/3);
            margin-bottom: 0;
            margin-right: rem(40);

            &:last-child {
                margin-bottom: 0;
                margin-right: 0;
            }

            @for $i from 1 through $limit {
                &:nth-child(#{$i}) {
                    margin-top: ($i - 1) * 15%;
                }
            }
        }
    }
}
