@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

.home-banner {
    position: relative;
    transition: opacity 1.5s ease;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: rem(86);
        background-image: linear-gradient(
            to bottom,
            hsla(0, 0%, 0%, 0.46) 0%,
            hsla(0, 0%, 0%, 0.454) 8.1%,
            hsla(0, 0%, 0%, 0.438) 15.5%,
            hsla(0, 0%, 0%, 0.412) 22.5%,
            hsla(0, 0%, 0%, 0.379) 29%,
            hsla(0, 0%, 0%, 0.341) 35.3%,
            hsla(0, 0%, 0%, 0.298) 41.2%,
            hsla(0, 0%, 0%, 0.253) 47.1%,
            hsla(0, 0%, 0%, 0.207) 52.9%,
            hsla(0, 0%, 0%, 0.162) 58.8%,
            hsla(0, 0%, 0%, 0.119) 64.7%,
            hsla(0, 0%, 0%, 0.081) 71%,
            hsla(0, 0%, 0%, 0.048) 77.5%,
            hsla(0, 0%, 0%, 0.022) 84.5%,
            hsla(0, 0%, 0%, 0.006) 91.9%,
            hsla(0, 0%, 0%, 0) 100%
        );
        background-repeat: no-repeat;
        opacity: 1;
        transition: opacity 0.4s ease;
    }

    .scroll-down {
        position: absolute;
        bottom: rem(map-get($gutter, "mobile"));
        right: rem(map-get($gutter, "mobile"));
    }

    .carousel {
        overflow: hidden;
        position: relative;
        .carousel-track {
            display: flex;
            transition: transform 1s ease;
            > * {
                width: 100%;
                flex-shrink: 0;
            }
        }
        .carousel-controls {
            margin-bottom: rem(30);
            button {
                text-transform: uppercase;
                background: none;
                border: none;
                color: $white;
                padding: 0;
                cursor: pointer;
                &[disabled] {
                    opacity: 0.5;
                    cursor: default;
                }
            }
            .line {
                width: rem(52);
                height: rem(2);
                background-color: rgba($white, 0.25);
                margin: 0 rem(12) rem(6) rem(12);
                display: inline-block;
            }
        }
    }
}
@media screen and (min-width: $screen-tablet) {
    .home-banner {
        &:after {
            height: rem(140);
        }

        .scroll-down {
            bottom: rem(map-get($gutter, "tablet"));
            right: rem(map-get($gutter, "tablet"));
        }
    }
}

@media screen and (min-width: $screen-desktop) {
    .home-banner {
        &:after {
            height: rem(180);
        }

        .scroll-down {
            bottom: rem(map-get($gutter, "desktop"));
            right: rem(map-get($gutter, "desktop"));
        }
    }
}
