@import "../../styles/imports/variables.scss";
@import "../../styles/imports/utils.scss";

@keyframes arrow-animate {
    0% {
        transform: translateX(-20px);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateX(0);
    }
}

.link-with-arrow {
    display: flex;
    justify-content: space-between;
    width: 100%;
    
    .link-with-arrow-text {
        max-width: 90%;
    }

    h3 {
        margin-bottom: rem(10);
    }

    &:hover {
        svg {
            animation: arrow-animate 1.7s ease infinite;
        }
    }
}

@media screen and (min-width: $screen-tablet) {
    .link-with-arrow {
        .link-with-arrow-text {
            max-width: 6/8 * 100%;
        }

        h3 {
            margin-bottom: rem(20);
        }
    }
}
